.company_section {
    padding: 0.5rem;
    margin-bottom: 1.5rem;
}

.experience__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    align-items: center;
    gap: 1rem
}


.experience__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 40%;
}

.experience__item h3 {
    margin: 1.2rem 0 2rem;
}

.experience__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem; 
}

.company__logo {
    width: 120px;
    max-height: 60px
}

@media screen and (max-width: 1024px){
    .experience__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}
  
@media screen and (max-width: 600px){
    .experience__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}