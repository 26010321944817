.footer {
    margin-bottom: 5rem
}

.header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    left: 5rem;
    bottom: 1rem;
    margin-top:2rem
}



.cta {
    display: flex;
    justify-content: left;
    gap: 1.2rem;
    margin-top: 2.5rem;
  }


  @media screen and (max-width: 600px){
    .cta{
        justify-content: center;
    }
  }
  
  